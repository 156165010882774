import { get, post, patch } from "./api.service";

const PlannerService = {
  getPlanner(id) {
    let params = { id: id };
    return get("/user/planner", params);
  },

  getPlannerAssignment(id) {
    let params = { id: id };
    return get("/user/planner/assignment", params);
  },

  getPlannerAssignments(params) {
    return get("/user/planner/assignment/search", params);
  },

  createPlannerSubmission(params) {
    return post("/user/planner/submission", params);
  },

  patchPlannerSubmission(params) {
    return patch("/user/planner/submission", params);
  },

  getPlannerSubmission(id) {
    let params = { id: id };
    return get("/user/planner/submission", params);
  },

  searchPlannerSubmissions(params) {
    return get("/user/planner/submission/search", params);
  },
};

export { PlannerService };
