function load(component) {
  // '@' is aliased to src/components
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${component}.vue`);
}

export default [
  {
    path: "/",
    component: () => import("@views/Index"),
    meta: {},
    children: [
      {
        path: "planners",
        name: "planners",
        meta: {
          public: false,
          section: "planners",
        },
        component: load("SafetyPlanners"),
        props: true,
      },
      {
        path: "planner/:plannerId?/:plannerAssignmentId?/:plannerAssignmentDueDateId?/:plannerSubmissionId?",
        name: "planner",
        meta: {
          public: false,
          section: "planners",
        },
        component: load("SafetyPlanner"),
        props: true,
      },
      {
        path: "inspection",
        name: "inspection",
        meta: {
          public: false,
          section: "inspection",
        },
        component: load("Inspection"),
        props: true,
      },
      {
        path: "training",
        name: "training",
        meta: {
          public: false,
          section: "training",
        },
        component: load("Training"),
        props: true,
      },
      {
        path: "home",
        name: "home",
        meta: {
          public: false,
          section: "dashboard",
        },
        component: load("Home"),
        props: true,
      },
      {
        path: "/",
        name: "dashboard",
        meta: {
          public: false,
          section: "dashboard",
        },
        component: load("Dashboard"),
        props: true,
      },
      {
        path: "timeclock",
        name: "timeclock",
        meta: {
          public: false,
          section: "timeclock",
        },
        component: load("TimeClock"),
        props: true,
      },
      {
        path: "timeclockdetail/:timeClockId?",
        name: "timeclockdetail",
        meta: {
          public: false,
          section: "timeclock",
        },
        component: load("TimeClockDetail"),
      },
      {
        path: "messages",
        name: "messages",
        meta: {
          public: false,
          section: "messages",
        },
        component: load("Messages"),
      },
      {
        path: "forms",
        name: "forms",
        meta: {
          public: false,
          section: "forms",
        },
        component: load("Forms"),
        props: true,
      },
      {
        path: "form/:id",
        name: "form",
        meta: {
          public: false,
          section: "forms",
        },
        component: load("Form"),
        props: true,
      },
      {
        path: "assignment/:id",
        name: "assignment",
        meta: {
          public: false,
          section: "forms",
        },
        component: load("Form"),
        props: true,
      },
      {
        path: "training",
        name: "training",
        meta: {
          public: false,
          section: "training",
        },
        component: load("TrainingCourses"),
        props: true,
      },
      {
        path: "training/:id",
        name: "training-course",
        meta: {
          public: false,
          section: "training",
        },
        component: load("TrainingCourse"),
        props: true,
      },
      {
        path: "trainingexam/:id",
        name: "trainingexam",
        meta: {
          public: false,
          section: "training",
        },
        component: load("TrainingExam"),
        props: true,
      },
      {
        path: "submission/:id",
        name: "submission",
        meta: {
          public: false,
          section: "forms",
        },
        component: load("Form"),
        props: true,
      },
      {
        path: "reports",
        name: "reports",
        meta: {
          public: false,
          section: "reports",
        },
        component: load("Reports"),
      },
      {
        path: "documents",
        name: "documents",
        meta: {
          public: false,
          section: "documents",
        },
        component: load("Documents"),
      },
      {
        path: "document/:id",
        name: "document",
        meta: {
          public: false,
          section: "documents",
        },
        component: load("Document"),
      },
      {
        path: "issues",
        name: "issues",
        meta: {
          public: false,
          section: "issues",
        },
        props: true,
        component: load("Issues"),
      },
      {
        path: "issue/:id?",
        name: "issue",
        meta: {
          public: false,
          section: "issues",
        },
        props: true,
        component: load("Issue"),
      },
      {
        path: "account",
        name: "account",
        meta: {
          public: false,
          section: "more",
        },
        component: load("Account"),
      },
      {
        path: "register/user",
        name: "registeruser",
        meta: {
          public: true,
        },
        component: load("RegisterUser"),
      },
      {
        path: "login",
        name: "login",
        meta: {
          public: true,
        },
        component: load("Login"),
      },
      {
        path: "forgotpassword",
        name: "forgotpassword",
        meta: {
          public: true,
        },
        component: load("ForgotPassword"),
      },
      {
        path: "status",
        name: "status",
        meta: {
          public: true,
        },
        component: load("Status"),
      },
      {
        path: "help",
        name: "help",
        meta: {
          public: true,
          noBackground: true,
        },
        component: load("Help"),
      },
      {
        path: "diagnostics",
        name: "Diagnostics",
        meta: {
          public: true,
          noBackground: true,
        },
        component: () => import("@views/diagnostics/Diagnostics.vue"),
      },
      {
        path: "*",
        meta: {
          public: true,
        },
        component: load("PageNotFound"),
      },
    ],
  },
];
